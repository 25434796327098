import {goToDetailRoute,typeSelect} from "../js/tool";

export default {
    name: '音频',
    listTitle: '音频列表',
    url: '/audio', // rest接口表名
    query: '&sort=order,-id&expand=teacher,album,activity', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '音频名称', dataIndex: 'name'},
            {
                title: '音频封面',
                dataIndex: 'img',
                customRender: text => {
                    return <img class="avatar" src={text} />
                }
            },
            {
                title: '主播老师',
                dataIndex: 'teacher',
                customRender: text => {
                    return <span>{text ? text.name : '—'}</span>
                }
            },
            {title: '排序', dataIndex: 'order'},
        ]
    },
    defaultQuery: {is_home: 0},
    // 列表查询表单
    queryForm: [
        {
            key: 'is_home',
            label: '',
            component: 'a-select',
            update: ['change'],
            props: {
                options: [
                    {key: 0, title: '全部'},
                    {key: 1, title: '推荐到首页'},
                ],
            },
            style: {
                width: '180px'
            },
        },
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '请输入音频名称查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '音频名称',
            component: 'a-input',
            props: {
                placeholder: '请输入音频名称',
            },
            rules: [{ required: true, message: '请输入音频名称', trigger: 'blur' }]
        },
        {
            key: 'img',
            label: '音频封面',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传音频封面', trigger: 'change' }]
        },
        {
            key: 'url',
            label: '音频文件',
            component: 'form-upload',
            props: {
                mediaType: 'audio'
            },
            listeners: {
                duration(val) {
                    this.$set(this.form, 'duration',  Math.round(val) + '');
                }
            },
            rules: [{ required: true, message: '请上传音频文件', trigger: 'change' }]
        },
        {
            key: 'duration',
            label: '音频时长',
            component: 'a-input-number',
            props: {
                placeholder: '请输入音频时长（单位秒）例如1:30的音频时长为90',
            },
            style: {
                width: '240px'
            },
            rules: [
                { required: true, message: '请输入音频时长', trigger: 'change' },
                { min: 1, message: '音频时长输入不正确', trigger: 'change' }
            ]
        },
        {
            key: 'teacher',
            label: '关联老师',
            component: 'select-entity',
            props: {
                btnText: '选择老师',
                type: 'teacher'
            },
            listeners: {
                change(val) {
                    this.$set(this.form, 'teacher_id',  val ? val.id : null);
                }
            },
            rules: [{ required: true, message: '请选择音频关联的老师', trigger: 'change' }]
        },
        {
            key: 'album',
            label: '所属专辑',
            component: 'select-entity',
            props: {
                btnText: '选择专辑',
                type: 'album'
            },
            listeners: {
                change(val) {
                    this.$set(this.form, 'album_id',  val ? val.id : null);
                }
            },
            rules: [{ required: true, message: '请选择音频所属的专辑', trigger: 'change' }]
        },
        {
            key: 'type',
            label: '所属板块',
            component: 'a-select',
            update: ['change'],
            props: {
                placeholder:'请选择所属板块',
                options: [
                    {key: 1, title: '艺术创作'},
                    {key: 2, title: '生命科学'},
                    {key: 3, title: '家国天下'},
                    {key: 4, title: '社会科学'},
                    {key: 5, title: '科技创新'},
                    {key: 6, title: '人文历史'},
                    {key: 7, title: '人与自然'},
                    {key: 8, title: '地球家园'}
                ],
            },
            style: {
                width: '240px'
            },
            rules: [{ required: true, message: '请选择所属板块', trigger: 'change' }]
        },
        {
            key: 'activity',
            label: '关联活动',
            component: 'select-entity',
            props: {
                btnText: '选择活动',
                type: 'activity'
            },
            listeners: {
                change(val) {
                    this.$set(this.form, 'activity_id',  val ? val.id : null);
                }
            },
        },
        {
            key: 'desc',
            label: '简介',
            component: 'a-input',
            props: {
                placeholder: '音频简介',
            }
        },
        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
        {
            key: 'is_home',
            label: '推荐到首页',
            component: 'a-radio-group',
            options: [
                {value: 1, text: '是'},
                {value: 0, text: '否'},
            ]
        },
        {
            key: 'picture',
            label: '图片/绘本',
            component: 'rich-editor'
        },
    ],
    // 详情接口expand字段
    expand: '?expand=teacher,album,activity',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '音频封面',
                key: 'img',
                slot: entity => {
                    return <img class="avatar" src={entity.img}/>
                }
            },
            {
                label: '所属板块',
                key: 'type',
                slot: entity => {
                    return <div>{typeSelect(entity.type)}</div>
                }
            },
            {
                label: '音频时长（秒）',
                key: 'duration'
            },
            {
                label: '排序',
                key: 'order'
            },
            {
                label: '音频文件',
                key: 'url',
                slot: entity => {
                    return <audio src={entity.url} controls class="audio-preview"></audio>
                }
            },
            {
                label: '关联老师',
                key: 'teacher',
                slot: entity => {
                    return entity.teacher ? (
                        <div class="flex-box align-center album-teacher" onClick={() => {goToDetailRoute(ctx, {item: entity.teacher, type: 'teacher'})}}>
                            <span style="margin-right: 12px">{entity.teacher.name}</span>
                            <img src={entity.teacher.img} class="avatar mini" />
                        </div>
                    ) : <span>无</span>
                }
            },
            {
                label: '关联活动',
                key: 'activity',
                slot: entity => {
                    return entity.activity ? (
                        <div class="flex-box align-center album-activity" onClick={() => {goToDetailRoute(ctx, {item: entity.activity, type: 'activity'})}}>
                            <span style="margin-right: 12px">{entity.activity.name}</span>
                            <img src={entity.activity.img} class="avatar mini" />
                        </div>
                    ) : <span>无</span>
                }
            },
            {
                label: '图片/绘本',
                key: 'picture',
                span: 3,
                slot: entity => {
                    return <div domPropsInnerHTML={entity.picture || '无'}></div>
                }
            }
        ]
    }
}
