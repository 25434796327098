import city from "./city";
import axios from '../../common/js/req';
const municipality = ['北京市', '上海市' , '天津市', '重庆市'] //直辖市

const getMuseumRoom = function (id) {
    return axios(`/museum-house?pageSize=1000&filter[museum_id]=${id}`).then(res => {
        let temp = res.data.map(item => {
            return {
                key: item.id,
                title: item.name
            }
        });
        return temp;
    })
}

export default {
    name: '展览',
    listTitle: '展览列表',
    url: '/exhibition', // rest接口表名
    query: '&sort=order,-id&expand=museum,exhibitionHouse', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '展览名称', dataIndex: 'name'},
            {
                title: '展览图片',
                dataIndex: 'img',
                customRender: text => {
                    return <img class="avatar" src={text} />
                }
            },
            {
                title: '展馆',
                dataIndex: 'museum',
                customRender: text => {
                    return <span>{text ? text.name : ''}</span>
                }
            },
            {
                title: '展厅',
                dataIndex: 'exhibitionHouse',
                customRender: text => {
                    return <span>{text ? text.name : ''}</span>
                }
            },
            {
                title: '城市',
                dataIndex: 'province',
                customRender: (text, record) => {
                    return <span>{municipality.indexOf(text) >= 0 ? text : record.city}</span>
                }
            },
            {title: '价格（元）', dataIndex: 'price'},
            {title: '排序', dataIndex: 'order'},
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '请输入展览名称查询',
            },
        },
        {
            key: 'city',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '请输入城市名称查询',
            },
        }
    ],
    // 自定义查询链接
    customUrl: function(url, form) {
        let res = '';
        if(form.name) {
            res += `&filter[name][like]=${form.name}`;
        }
        if(form.city) {
            res += `&filter[or][0][city][like]=${form.city}&filter[or][1][province][like]=${form.city}`;
        }
        return res;
    },
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '展览名称',
            component: 'a-input',
            props: {
                placeholder: '请输入展览名称',
            },
            rules: [{ required: true, message: '请输入展览名称', trigger: 'blur' }]
        },
        {
            key: 'img',
            label: '展览图片',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传展览图片', trigger: 'change' }]
        },
        {
            key: 'time',
            label: '展览时间',
            component: 'a-range-picker',
            props: {
                showTime: {format: 'HH:mm'},
                format: 'YYYY-MM-DD HH:mm',
                valueFormat: 'YYYY-MM-DD HH:mm',
                placeholder: ['开始时间', '结束时间']
            },
            listeners: {
                change(val) {
                    if(val && val.length > 1) {
                        this.$set(this.form, 'start_time', val[0]);
                        this.$set(this.form, 'end_time', val[1]);
                    } else {
                        this.$set(this.form, 'start_time', null);
                        this.$set(this.form, 'end_time', null);
                    }
                }
            },
        },
        {
            key: 'museum',
            label: '展馆',
            component: 'select-entity',
            props: {
                btnText: '选择博物馆',
                type: 'museum'
            },
            listeners: {
                change(val) {
                    this.$set(this.form, 'museum_id',  val ? val.id : null);
                    if(val) {
                        // 获取博物馆下所有展厅列表
                        getMuseumRoom(val.id).then(options => {
                            this.$set(this.controls[4], "props", {
                                placeholder: '请选择展厅',
                                options: options,
                                disabled: false
                            });
                        })
                        // 将博物馆的位置信息城市信息自动填充到表单上
                        if(!this.form.position) {
                            this.$set(this.form, 'point', val.point);
                            this.$set(this.form, 'location', val.location);
                            this.$set(this.form, 'position', {address: val.location, latLng: val.point});
                        }
                        if(!this.form.area) {
                            this.$set(this.form, 'area', [val.province, val.city, val.district]);
                            this.$set(this.form, 'province', val.province);
                            this.$set(this.form, 'city', val.city);
                            this.$set(this.form, 'district', val.district);
                        }
                    } else {
                        this.$set(this.controls[4], "props", {
                            disabled: true,
                            placeholder: "请先选择展馆"
                        });
                    }
                }
            },
            rules: [{ required: true, message: '请选择展馆', trigger: 'change' }]
        },
        {
            key: 'exhibit_house',
            label: '展厅',
            component: 'a-select',
            props: {
                disabled: true,
                options: [],
                placeholder: "请先选择展馆"
            },
            style: {
                width: '240px'
            },
            rules: [{ required: true, message: '请选择展厅', trigger: 'change' }]
        },
        {
            key: 'position',
            label: '位置信息',
            component: 'coordinate-picker',
            listeners: {
                change(val) {
                    this.$set(this.form, 'point',  val ? val.latLng : null);
                    this.$set(this.form, 'location',  val ? val.address : null);
                }
            },
            rules: [{ required: true, message: '请选择博物馆所在位置', trigger: 'change' }]
        },
        {
            key: 'point',
            label: '位置坐标',
            component: 'a-input',
            props: {
                placeholder: '请输入坐标',
            },
            rules: [{ required: true, message: '请输入坐标', trigger: 'blur' }]
        },
        {
            key: 'area',
            label: '所在区域',
            component: 'a-cascader',
            props: {
                options: city,
                defaultValue: ['浙江省', '杭州市', '西湖区'],
            },
            listeners: {
                change(val) {
                    if(val) {
                        this.$set(this.form, 'province',  val[0]);
                        this.$set(this.form, 'city',  val[1]);
                        this.$set(this.form, 'district',  val[2]);
                    } else {
                        this.$set(this.form, 'province',  null);
                        this.$set(this.form, 'city',  null);
                        this.$set(this.form, 'district',  null);
                    }
                }
            },
            rules: [{ required: true, message: '请选择博物馆所在区域', trigger: 'change' }]
        },
        {
            key: 'price',
            label: '价格',
            component: 'a-input',
            props: {
                placeholder: '请输入价格',
            },
        },
        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
        {
            key: 'detail',
            label: '详情',
            component: 'rich-editor'
        },
    ],
    // 详情接口expand字段
    expand: '?expand=museum,exhibitionHouse,wantCount,likeCount',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    component: 'ExhibitionDetail',
    // 编辑前处理函数
    beforeEdit: function(detail) {
        return new Promise(resolve => {
            getMuseumRoom(detail.museum_id).then(options => {
                this.detailForm.$set(this.detailForm.controls[4], "props", {
                    placeholder: '请选择展厅',
                    options: options,
                    disabled: false
                });
            })
            resolve();
        })
    },
    dealDetail: info => new Promise(resolve => {
        if(info.start_time && info.end_time) {
            info.time = [info.start_time, info.end_time];
        }
        if(info.location && info.point) {
            info.position = {
                address: info.location,
                latLng: info.point
            }
        }
        if(info.province && info.city && info.district) {
            info.area = [info.province, info.city, info.district];
        }
        resolve(info);
    })
}
