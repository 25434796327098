export default {
    name: '专辑',
    listTitle: '专辑列表',
    url: '/album', // rest接口表名
    query: '&sort=order,-id&expand=teacher', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '专辑名称', dataIndex: 'name'},
            {
                title: '专辑封面',
                dataIndex: 'img',
                customRender: text => {
                    return <img class="avatar" src={text} />
                }
            },
            {title: '专辑简介', dataIndex: 'info'},
            {title: '排序', dataIndex: 'order'},
            {
                title: '首页显示',
                dataIndex: 'is_home',
                customRender: text => {
                    return <span>{text == 1 ? '是' : '否'}</span>
                }
            },
        ]
    },
    defaultQuery: {is_home: 0},
    // 列表查询表单
    queryForm: [
        {
            key: 'is_home',
            label: '',
            component: 'a-select',
            update: ['change'],
            props: {
                options: [
                    {key: 0, title: '全部'},
                    {key: 1, title: '推荐到首页'},
                ],
            },
            style: {
                width: '180px'
            },
        },
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '请输入专辑名称查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '专辑名称',
            component: 'a-input',
            props: {
                placeholder: '请输入专辑名称',
            },
            rules: [{ required: true, message: '请输入专辑名称', trigger: 'blur' }]
        },
        {
            key: 'img',
            label: '专辑封面',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传专辑封面', trigger: 'change' }]
        },
        {
            key: 'info',
            label: '专辑简介',
            component: 'a-input',
            props: {
                placeholder: '请输入专辑简介',
            },
            rules: [{ required: true, message: '请输入专辑简介', trigger: 'blur' }]
        },
        {
            key: 'teacher',
            label: '关联老师',
            component: 'select-entity',
            props: {
                btnText: '选择老师',
                type: 'teacher'
            },
            listeners: {
                change(val) {
                    this.$set(this.form, 'teacher_id',  val ? val.id : null);
                }
            },
            rules: [{ required: true, message: '请选择音频关联的老师', trigger: 'change' }]
        },
        {
            key: 'count',
            label: '集数',
            component: 'a-input-number',
            props: {
                placeholder: '请输入集数',
            },
            style: {
                width: '240px'
            }
        },
        {
            key: 'status',
            label: '专辑状态',
            component: 'a-radio-group',
            options: [
                {value: 0, text: '更新中'},
                {value: 1, text: '已完结'},
            ]
        },
        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
        {
            key: 'is_home',
            label: '推荐到首页',
            component: 'a-radio-group',
            options: [
                {value: 1, text: '是'},
                {value: 0, text: '否'},
            ]
        },
        {
            key: 'detail',
            label: '详细介绍',
            component: 'rich-editor'
        },
    ],
    // 新增实体初始化字段
    init: {
        status: 0
    },
    // 详情接口expand字段
    expand: '?expand=teacher',
    // 详情自定义渲染
    component: 'AlbumDetail'
}
