const bannerTypes = [
    {key: 1, title: '活动'},
    {key: 2, title: '专辑'},
    {key: 3, title: '展览'},
]

const bannerTypeLinkProps = [
    {btnText: '选择活动', type: 'activity'},
    {btnText: '选择专辑', type: 'album'},
    {btnText: '选择展览', type: 'exhibition'}
]

const getFormProps = function(type) {
    let props = bannerTypeLinkProps[type - 1]
    return props ? {...props} : null;
}

const getBannerTypeText = function (type) {
    let res = bannerTypes.find(t => t.key == type);
    return res ? res.title : null;
}

export default {
    name: 'Banner',
    listTitle: 'Banner列表',
    url: '/banner', // rest接口表名
    query: '&sort=order,-id&expand=link', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                title: '图片',
                dataIndex: 'img',
                customRender: text => {
                    return <img class="avatar" src={text} />
                }
            },
            {
                title: '类型',
                dataIndex: 'type',
                customRender: text => {
                    return <span>{getBannerTypeText(text)}</span>
                }
            },
            {
                title: '跳转对象', dataIndex: 'link',
                customRender: text => {
                    return <span>{text ? text.name : ''}</span>
                }
            },
            {title: '排序', dataIndex: 'order'},
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'type',
        label: '',
        component: 'a-select',
        update: ['change'],
        props: {
            options: bannerTypes,
            placeholder: "选择类型筛选"
        },
        style: {
            width: '180px'
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'img',
            label: 'banner图',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传banner图', trigger: 'change' }]
        },
        {
            key: 'type',
            label: '链接类型',
            component: 'a-select',
            props: {
                options: bannerTypes,
            },
            style: {
                width: '240px'
            },
            listeners: {
                change(val) {
                    let props = getFormProps(val);
                    if(props) {
                        for(let key in props) {
                            this.$set(this.controls[2].props, key, props[key]);
                        }
                    }
                    this.$set(this.form, 'link', null);
                }
            },
            rules: [{ required: true, message: '请选择链接类型', trigger: 'change' }]
        },
        {
            key: 'link',
            label: '跳转对象',
            component: 'select-entity',
            props: {
                btnText: '选择活动',
                type: 'activity'
            },
            listeners: {
                change(val) {
                    this.$set(this.form, 'link_id',  val ? val.id : null);
                }
            },
            rules: [{ required: true, message: '请选择音频关联的老师', trigger: 'change' }]
        },
        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
    ],
    init: {type: 1},
    // 详情接口expand字段
    expand: '?expand=link',
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '链接类型',
                key: 'type',
                slot: entity => {
                    return <span>{getBannerTypeText(entity.type)}</span>
                }
            },
            {
                label: '跳转对象',
                key: 'link',
                slot: entity => {
                    return <span>{entity.link ? entity.link.name : ''}</span>
                }
            },
            {
                label: '排序',
                key: 'order'
            },
            {
                label: 'banner',
                key: 'img',
                slot: entity => {
                    return <img class="avatar" src={entity.img}/>
                }
            },
        ]
    }
}
