import {base} from "./formItems";
import city from "./city";
export default {
    name: '活动',
    listTitle: '活动列表',
    url: '/activity', // rest接口表名
    query: '&sort=order,-id&expand=teacher,museum', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '活动名称', dataIndex: 'name'},
            {
                title: '活动封面',
                dataIndex: 'img',
                customRender: text => {
                    return <img class="avatar" src={text} />
                }
            },
            {title: '排序', dataIndex: 'order'},
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'name',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '请输入活动名称查询',
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '活动名称',
            component: 'a-input',
            props: {
                placeholder: '请输入活动名称',
            },
            rules: [{ required: true, message: '请输入活动名称', trigger: 'blur' }]
        },
        {
            key: 'img',
            label: '活动封面',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传活动封面', trigger: 'change' }]
        },
        {
            key: 'time',
            label: '活动时间',
            component: 'a-range-picker',
            props: {
                showTime: {format: 'HH:mm'},
                format: 'YYYY-MM-DD HH:mm',
                valueFormat: 'YYYY-MM-DD HH:mm',
                placeholder: ['开始时间', '结束时间']
            },
            listeners: {
                change(val) {
                    this.$set(this.form, 'start_time', val[0]);
                    this.$set(this.form, 'end_time', val[1]);
                }
            },
            rules: [{ required: true, message: '请选择活动时间', trigger: 'change' }]
        },
        {
            key: 'enroll',
            label: '报名时间',
            component: 'a-range-picker',
            props: {
                showTime: {format: 'HH:mm'},
                format: 'YYYY-MM-DD HH:mm',
                valueFormat: 'YYYY-MM-DD HH:mm',
                placeholder: ['开始时间', '结束时间']
            },
            listeners: {
                change(val) {
                    this.$set(this.form, 'enroll_start_time', val[0]);
                    this.$set(this.form, 'enroll_end_time', val[1]);
                }
            },
            rules: [{ required: true, message: '请选择活动报名时间', trigger: 'change' }]
        },
        {
            key: 'status',
            label: '地点状态',
            component: 'a-radio-group',
            options: [
                { value: 1, text: '活动地点' },
                { value: 2, text: '关联博物馆' },
            ],
            rules: [{ required: true, message: '请选择地点状态', trigger: 'change' }]
        },
        {
            key: 'position',
            label: '位置信息',
            component: 'coordinate-picker',
            listeners: {
                change(val) {
                    this.$set(this.form, 'point',  val ? val.latLng : null);
                    this.$set(this.form, 'location',  val ? val.address : null);
                    this.$set(this.form, 'address',  val ? val.poiname : null);
                }
            },
            rely: {
                key: 'status',
                value: 1
            },
            rules: [{ required: true, message: '请选择位置信息', trigger: 'change' }]
        },
        {
            key: 'point',
            label: '位置坐标',
            component: 'a-input',
            props: {
                placeholder: '请输入坐标',
            },
            rely: {
                key: 'status',
                value: 1
            },
            rules: [{ required: true, message: '请输入位置坐标', trigger: 'change' }]
        },
        {
            key: 'location',
            label: '活动地点',
            component: 'a-input',
            props: {
                placeholder: '请输入活动地点',
            },
            rely: {
                key: 'status',
                value: 1
            },
            rules: [{ required: true, message: '请选择活动地点', trigger: 'change' }]
        },
        {
            key: 'area',
            label: '所在区域',
            component: 'a-cascader',
            props: {
                options: city,
                defaultValue: ['浙江省', '杭州市', '西湖区'],
            },
            listeners: {
                change(val) {
                    if(val) {
                        this.$set(this.form, 'province',  val[0]);
                        this.$set(this.form, 'city',  val[1]);
                        this.$set(this.form, 'district',  val[2]);
                    } else {
                        this.$set(this.form, 'province',  null);
                        this.$set(this.form, 'city',  null);
                        this.$set(this.form, 'district',  null);
                    }
                }
            },
            rely: {
                key: 'status',
                value: 1
            },
            rules: [{ required: true, message: '请选择所在区域', trigger: 'change' }]
        },

        {
            key: 'museum',
            label: '关联博物馆',
            component: 'select-entity',
            props: {
                btnText: '选择博物馆',
                type: 'museum'
            },
            listeners: {
                change(val) {
                    this.$set(this.form, 'museum_id',  val ? val.id : null);
                }
            },
            rely: {
                key: 'status',
                value: 2
            },
            rules: [{ required: true, message: '请选择关联博物馆', trigger: 'change' }]
        },
        {
            key: 'teacher',
            label: '关联老师',
            component: 'select-entity',
            props: {
                btnText: '选择老师',
                type: 'teacher'
            },
            listeners: {
                change(val) {
                    this.$set(this.form, 'teacher_id',  val ? String(val.id) : null);
                }
            },
            rules: [{ required: true, message: '请选择活动关联的老师', trigger: 'change' }]
        },
        {
            key: 'tel',
            label: '联系方式',
            component: 'a-input',
            props: {
                placeholder: '请输入联系方式',
            },
            rules: [{ required: true, message: '请输入联系方式', trigger: 'blur' }]
        },
        {
            key: 'priceNum',
            label: '价格',
            component: 'a-input-number',
            props: {
                placeholder: '输入0表示免费，单位元',
            },
            style: {
                width: '240px'
            },
            listeners: {
                change(val) {
                    this.$set(this.form, 'price',  val * 100);
                }
            },
            rules: [{ required: true, message: '请输入价格', trigger: 'change' }]
        },
        {
            key: 'people_count',
            label: '活动人数',
            component: 'a-input-number',
            props: {
                placeholder: '参与活动的人数',
            },
            style: {
                width: '240px'
            }
        },
        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
        {
            key: 'info',
            label: '活动详情',
            component: 'rich-editor'
        },
        {
            key: 'notice',
            label: '活动须知',
            component: 'rich-editor'
        },
    ],
    // 详情接口expand字段
    expand: '?expand=teacher,museum',
    init: {price: 0}, // 新增实体初始化字段
    // 详情渲染字段
    component: 'ActivityDetail',
    beforeEdit: function(detail) {
        return new Promise(resolve => {
            detail.priceNum = detail.price / 100;
            resolve();
        })
    },
    beforeUpdate: form => new Promise(resolve => {
        if(!form.form_config) {
            form.form_config = JSON.stringify(base);
        }
        resolve(form)
    }),
    // 编辑实体前处理函数
    dealDetail: info => new Promise(resolve => {
        info.time = [info.start_time, info.end_time];
        info.position = {
            address: info.location,
            latLng: info.point,
            poiname:info.address
        }
        if(info.province && info.city && info.district) {
            info.area = [info.province, info.city, info.district];
        }
        info.enroll = [info.enroll_start_time, info.enroll_end_time];
        resolve(info);
    })
}

/**
 * 获取活动报名状态值
 * 免费活动status取enroll的is_del
 * @param price 活动价格
 * @param enroll 活动报名实体
 * @returns status  0 已报名 1 用户取消 2 支付超时取消 3 待支付 4 已支付 5 退款中 6 已退款 7 退款失败
 * enroll.is_del    0 正常报名 1 报名主动取消 2 报名自动取消 3 排队主动取消 4 排队自动取消
 * enroll.in_queue  0 不在排队 1 在排队 2 正常报名
 * 订单状态 status  1 未支付 2 已支付 3 退款中 4 已退款 5 退款失败 6 超时取消
 */

export const getEnrollOrderStatus = function (price, enroll) {
    // 普通报名及排队的取消状态合并 通过in_queue区分排队还是普通报名
    let status = [0, 1, 2, 1, 2][enroll.is_del];
    // 付费活动
    if(price > 0) {
        let order = enroll.order;
        if(status == 0) {
            // enroll未取消且有订单状态设为订单状态 没有订单则为待支付
            if(order) {
                status = order.status * 1 + 2;
            } else {
                status = 3
            }
        }
    }
    return status * 1;
}

export const getEnrollOrderStatusText = function (price, enroll) {
    let status = enroll.status || getEnrollOrderStatus(price, enroll);
    let queue = enroll.in_queue;
    // 普通报名
    if(queue == 2) {
        return ['已报名', '报名取消', '支付超时，报名取消', '待支付', '已支付', '退款中', '已退款', '退款失败'][status];
    } else {
        //排队报名
        return [queue == 0 ? '已报名' : '排队中', '排队取消', '支付超时，排队取消', '待支付', '已支付', '退款中', '已退款', '退款失败'][status];
    }
}
