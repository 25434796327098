export default {
    name: '老师',
    listTitle: '老师列表',
    url: '/teacher', // rest接口表名
    query: '&sort=-id', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '姓名', dataIndex: 'name'},
            {
                title: '头像',
                dataIndex: 'img',
                customRender: text => {
                    return <img class="avatar" src={text} />
                }
            },
            {title: '简介', dataIndex: 'info'},
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'name',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '请输入老师姓名查询',
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '姓名',
            component: 'a-input',
            props: {
                placeholder: '请输入姓名',
            },
            rules: [{ required: true, message: '请输入姓名', trigger: 'blur' }]
        },
        {
            key: 'img',
            label: '头像',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传头像', trigger: 'change' }]
        },
        {
            key: 'info',
            label: '简介',
            component: 'a-input',
            rules: [{ required: true, message: '请输入简介', trigger: 'blur' }]
        },
        {
            key: 'detail',
            label: '详细介绍',
            component: 'rich-editor'
        },
    ],
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '头像',
                key: 'img',
                span: 1,
                slot: entity => {
                    return <img class="avatar" src={entity.img}/>
                }
            },
            {
                label: '简介',
                key: 'info',
                span: 2
            },
            {
                label: '详细介绍',
                key: 'detail',
                span: 3,
                slot: entity => {
                    return <div domPropsInnerHTML={entity.detail || '无'}></div>
                }
            }
        ]
    }
}
