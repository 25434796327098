import city from "./city";
export default {
    name: '博物馆',
    listTitle: '博物馆列表',
    url: '/museum', // rest接口表名
    query: '&sort-id', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '博物馆名称', dataIndex: 'name'},
            {
                title: '封面图',
                dataIndex: 'img',
                customRender: text => {
                    return <img class="avatar" src={text} />
                }
            },
            {title: '地址', dataIndex: 'location'},
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'name',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '请输入博物馆名称查询',
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '博物馆名称',
            component: 'a-input',
            props: {
                placeholder: '请输入博物馆名称',
            },
            rules: [{ required: true, message: '请输入博物馆名称', trigger: 'blur' }]
        },
        {
            key: 'img',
            label: '博物馆封面',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传博物馆封面', trigger: 'change' }]
        },
        {
            key: 'position',
            label: '位置信息',
            component: 'coordinate-picker',
            listeners: {
                change(val) {
                    this.$set(this.form, 'point',  val ? val.latLng : null);
                    this.$set(this.form, 'location',  val ? val.address : null);
                }
            },
            rules: [{ required: true, message: '请选择博物馆所在位置', trigger: 'change' }]
        },
        {
            key: 'point',
            label: '位置坐标',
            component: 'a-input',
            props: {
                placeholder: '请输入坐标',
            },
            rules: [{ required: true, message: '请输入坐标', trigger: 'blur' }]
        },
        {
            key: 'area',
            label: '所在区域',
            component: 'a-cascader',
            props: {
                options: city,
                defaultValue: ['浙江省', '杭州市', '西湖区'],
            },
            listeners: {
                change(val) {
                    if(val) {
                        this.$set(this.form, 'province',  val[0]);
                        this.$set(this.form, 'city',  val[1]);
                        this.$set(this.form, 'district',  val[2]);
                    } else {
                        this.$set(this.form, 'province',  null);
                        this.$set(this.form, 'city',  null);
                        this.$set(this.form, 'district',  null);
                    }
                }
            },
            rules: [{ required: true, message: '请选择博物馆所在区域', trigger: 'change' }]
        },
        {
            key: 'open_time',
            label: '开放时间',
            component: 'a-input',
            props: {
                placeholder: '如周一闭馆，开放时间8:00-17:00',
            },
            rules: [{ required: true, message: '请输入开放时间', trigger: 'blur' }]
        },
        {
            key: 'detail',
            label: '详细介绍',
            component: 'rich-editor'
        },
    ],
    // 详情接口expand字段
    expand: '?expand=teacher',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    component: 'MuseumDetail',
    // 编辑实体前处理函数
    dealDetail: info => new Promise(resolve => {
        info.position = {
            address: info.location,
            latLng: info.point
        }
        if(info.province && info.city && info.district) {
            info.area = [info.province, info.city, info.district];
        }
        resolve(info);
    })
}
