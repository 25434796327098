import album from './album'
import audio from './audio'
import activity from './activity'
import teacher from './teacher'
import museum from "./museum";
import banner from "./banner";
import exhibition from "./exhibition";
export default {
    album,
    audio,
    activity,
    teacher,
    museum,
    banner,
    exhibition
}
